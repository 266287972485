import Vue from "vue";
import VueRouter from "vue-router";

// layouts
import Public from "../layouts/Public.vue";
import Private from "../layouts/Private.vue";
import store from "../store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // component: landing,
    redirect: { name: "dashboard" },
    // children: [{
    //     path: '',
    //     name: 'landing',
    //     component: landing,
    // }],
  },
  {
    path: "/login",
    component: Public,
    name: "Authentication",
    // children: [
    //   {
    //     path: "",
    //     name: "Authentication",
    //     component: Public,
    //   },
    // ],
  },
  {
    path: "*",
    name: "404",
    component: () =>
      import(
        /* webpackChunkName: "group-foo" */ "../components/errors/404.vue"
      ),
  },
  {
    path: "/500",
    name: "500",
    component: () =>
      import(
        /* webpackChunkName: "group-foo" */ "../components/errors/500.vue"
      ),
  },
  {
    path: "/zxvaBQTdsffavdsadasd/dasdasdasdasd",
    component: () =>
      import(/* webpackChunkName: "group-foo" */ "../views/door.vue"),
  },
  {
    path: "/system",
    component: Private,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "copy-trade",
        name: "copy-trade",
        component: () => import("../views/PrivateCopyTrade.vue"),
      },
      {
        path: "wallet",
        name: "wallet",
        component: () => import("../views/PrivateWallet2.vue"),
      },
      {
        path: "setting",
        name: "Setting",
        component: () => import("../views/PrivateSetting.vue"),
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("../views/PrivateDashboard2.vue"),
      },
      {
        path: "stake",
        name: "stake",
        component: () => import("../views/PrivateStake.vue"),
      },
      {
        path: "affiliate",
        name: "affiliate",
        component: () => import("../views/PrivateAffiliate.vue"),
      },
      {
        path: "support",
        name: "support",
        component: () => import("../views/PrivateSupport2.vue"),
      },
      {
        path: "transaction",
        name: "transaction",
        component: () => import("../views/PrivateTransaction.vue"),
      },
      {
        path: "happy",
        name: "crash",
        component: () =>
          import(/* webpackChunkName: "group-foo" */ "../views/DevCrash.vue"),
      },
      {
        path: "bitkachu",
        name: "bitkachu",
        component: () =>
          import(/* webpackChunkName: "group-foo" */ "../views/HappyTwo.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  // eslint-disable-next-line no-undef
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        // , offset: { x: 0, y: 10 }
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 1 };
    }
  },
});
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
router.beforeEach(async (to, from, next) => {
  const user = window.$cookies.get("user_session");
  
  if (user && to.name === "Authentication") {
    next({ name: "dashboard" });
  }

  
  if (to.matched.some((m) => m.meta.requiresAuth)) {
    if (user) {
      next();
      if (to.name === "Authentication") {
        next({ name: "dashboard" });
      }
      if (to.name == "demo") {
        store.commit("core/SET_ACCOUNT_TYPE", 1);
      } else {
        store.commit("core/SET_ACCOUNT_TYPE", 2);
      }
    } else {
      next({ name: "Authentication" });
    }
  } else {
    next();
  }
});
export default router;
