import { render, staticRenderFns } from "./Public.vue?vue&type=template&id=24858a2b&scoped=true"
import script from "./Public.vue?vue&type=script&lang=js"
export * from "./Public.vue?vue&type=script&lang=js"
import style0 from "./Public.vue?vue&type=style&index=0&id=24858a2b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24858a2b",
  null
  
)

export default component.exports