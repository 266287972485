import Vue from "vue";
import Vuex from "vuex";
import Auth from "./users/Auth.js";
import Dashboard from "./users/Dashboard.js";
import Agency from "./agency/Agency.js";
import Core from "./setting/Core.js";
import Trade from "./wallet/Trade.js";
import UserInfo from "./users/UserInfo.js";
import Ticket from "./ticket/Ticket.js";
import wallet from "./wallet/Index.js";
import copytrade from "./wallet/copy-trade.js";
import Insurance from "./insurance/Insurance.js";
import Statistical from "./wallet/Statistical";
import socket from "./socket";
import History from "./wallet/History.js";
import Member from "./users/Member.js";
import Stake from "./users/Stake.js";
import Notify from "./users/Notification.js";

Vue.use(Vuex);

Vue.config.devtools = true;
// Return vuex store
export default new Vuex.Store({
  state: {
    isLoad: false,
    soundsE: true,
    progress_loading: {
      task_index: 0,
      task_total: 0,
      percent: 0,
    },
    loadingStatus: true,

    percent: 0,
    showNotify: false,
  },
  getters: {
    Progress_loading: (state) => {
      return state.progress_loading;
    },
    ShowNotify: (state) => state.showNotify,
    LoadingStatus: (state) => state.loadingStatus,
    ProgressLoading: (state) => state.progress_loading,
  },
  mutations: {
    // loader
    onload(state) {
      state.isLoad = true;
    },

    outload(state) {
      state.isLoad = false;
    },
    setTask(state, data) {
      if (data.Total) {
        state.progress_loading.task_total = data.Total;
      }
      if (data.Task) {
        state.progress_loading.task_index = data.Task;
      }
      const percent =
        (state.progress_loading.task_index /
          state.progress_loading.task_total) *
        100;
      state.progress_loading.percent =
        percent >= 100 ? 100 : percent.toFixed(0);
    },
    setLoading(state, data) {
      state.loadingStatus = data.loading;
    },
    //get sound
    getSound(state, sound) {
      state.soundsE = sound;
    },
    SET_REQUEST_RESPONSE(state, data) {
      if (data.request) {
        state.progress_loading.task_request = data.request;
      }
      if (data.response) {
        state.progress_loading.task_response = data.response;
      }
      state.progress_loading.percent = (
        (state.progress_loading.task_response /
          state.progress_loading.task_request) *
        100
      ).toFixed(0);
    },
    toggleNotify(state, bool = !state.showNotify) {
      state.showNotify = bool;
    },
  },
  actions: {},
  modules: {
    auth: Auth,
    core: Core,
    trade: Trade,
    dashboard: Dashboard,
    userinfo: UserInfo,
    agency: Agency,
    ticket: Ticket,
    wallet: wallet,
    copytrade: copytrade,
    insurance: Insurance,
    statistical: Statistical,
    socket,
    history: History,
    member: Member,
    stake: Stake,
    notify: Notify,
  },
});
