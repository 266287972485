// eslint-disable-next-line no-undef
const axios = require("../config/axios.config.js").default;
export default {
  namespaced: true,
  state: () => ({
    statistic: {},
    list: {},
    tree: {},
    memberData: {},
  }),

  getters: {
    Statistic: (state) => state.statistic,
    List: (state) => {
      if (state.list.Member) {
        return state.list.Member.data;
      }
      return [];
    },
    ListPage: (state) => {
      if (state.list.Member) {
        return state.list.Member.last_page;
      }
      return 0;
    },
    Tree: (state) => {
      if (state.tree.trees) return state.tree.trees;
      return {};
    },
    Member: (state) => {
      if (state.memberData) {
        return state.memberData;
      }
      return {};
    },
  },

  actions: {
    req_getStatistic({ commit }) {
      axios.get("affiliate/statistical").then((res) => {
        commit("SET_STATISTIC", res);
      });
    },
    req_getMemberList({ commit }, input) {
      axios.get("affiliate/member-list", { params: input }).then((res) => {
        commit("SET_MEMBER_LIST", res);
      });
    },
    req_getTree({ commit }) {
      axios.get("affiliate/member-tree").then((res) => {
        commit("SET_MEMBER_TREE", res);
      });
    },
    async req_memberDetails({ commit }, input) {
      const res = await axios.get("affiliate/ajax/ajax-sale-user", {
        params: {
          userId: input,
        },
      });
      commit("SET_MEMBER_DATA", res);
    },
  },

  mutations: {
    SET_STATISTIC(state, data) {
      state.statistic = data;
    },
    SET_MEMBER_LIST(state, data) {
      state.list = data;
    },
    SET_MEMBER_TREE(state, data) {
      state.tree = data;
    },
    SET_MEMBER_DATA(state, data) {
      state.memberData = data;
    },
  },
};
