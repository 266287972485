const axios = require("../config/axios.config.js").default;
export default {
    namespaced: true,
    state: () => ({
        listSubject: {},
        listTicket: [],
        detail_ticket:{}
    }),

    getters: {
        ListSubject: state => { return state.listSubject },
        ListTicket: state => { return state.listTicket },
        DetailTicket: state => { return state.detail_ticket}
    },

    actions: {
        req_postSubject: async function ({ commit, dispatch }, input) {
            axios.post("ticket/post-ticket", input).then(function (response) {
                if (response) {
                    commit("SendTicketSuccess")
                    dispatch("req_getTicket");
                }
            })
        },
        req_getSubject: async function ({ commit }) {
            axios.get("ticket/subject").then(function (response) {
                commit("GetSubjectSuccess", response)
            })
        },
        req_getTicket: async function ({ commit }) {
            axios.get("ticket").then(function (response) {
                commit("GetTicketSuccess", response)
            })
        },
        req_getTicketDetail: async function ({ commit }, input) {
            axios.get("/ticket/get-ticket-detail/"+input).then(function (response) {
                if (response) {
                    commit("SET_DETAIL_TICKET", response)
                }

            })
        },
        req_postTicketMessage: async function ({ commit },input) {
            axios.post("ticket/post-ticket",input).then(function (response){
                if(response){
                    commit("SEND_MESSANGE_SUCCESS");
                }
            })
        }
    },

    mutations: {

        SendTicketSuccess: function () { },
        GetSubjectSuccess: function (state, data) {
            state.listSubject = data.listSubject;
        },
        GetTicketSuccess: function (state, data) {
            state.listTicket = data.ticket;
        },
        SET_DETAIL_TICKET: function(state,data){
            state.detail_ticket = data.ticketDetail;
        },
        SEND_MESSANGE_SUCCESS: async function (){}
    }
};