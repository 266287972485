// eslint-disable-next-line no-undef
const axios = require("../config/axios.config.js").default;
export default {
  namespaced: true,
  state: () => ({
    history: {},
    filter: {},
  }),

  getters: {
    History: (state) => {
      if (state.history.history) {
        return state.history.history.data;
      }
      return [];
    },
    HistoryTotalPage: (state) => {
      if(state.history.history) {
        return state.history.history.last_page;
      }
      return 0;
    },
    Filter: (state) => (id) => {
      if (state.filter.length > 0) {
        const action = state.filter.find((action) => action.MoneyAction_ID === id);
        if (action !== undefined) {
          return action.MoneyAction_Name;
        }
        return '';
      }
    },
  },

  actions: {
    req_getHistory({ commit }, input) {
      axios.get("wallet/historys", { params: input }).then((res) => {
        commit("SET_HISTORY", res);
      });
    },
    req_getMoneyActionsFilter({ commit }) {
      axios.get("wallet/action").then((res) => {
        commit("SET_FILTER", res);
      });
    },
  },

  mutations: {
    SET_HISTORY(state, data) {
      state.history = data;
    },
    SET_FILTER(state, data) {
      state.filter = data;
    },
  },
};
