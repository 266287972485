// eslint-disable-next-line no-undef
const axios = require("../config/axios.config.js").default;

export default {
    namespaced: true,
    state: () => ({
        listNotifySystem: null,
        listNotifyEvent: null,
        countNotify: null,
    }),

    getters: {
        ListNotifySystem: (state) => state.listNotifySystem,
        ListNotifyEvent: (state) => state.listNotifyEvent,
        CountNotify: (state) => state.countNotify,
    },

    actions: {
        req_getNotificationSystem({ commit }) {
            axios.get("notification/notifi-user").then((res) => {
                commit("SET_NOTIFICATION_SYSTEM", res);
            });
        },
        req_getNotificationEvent({ commit }) {
            axios.get("notification/get-event").then((res) => {
                commit("SET_NOTIFICATION_EVENT", res);
            });
        },
        req_getNotificationCount({ commit }) {
            axios.get("notification/count-notifi").then((res) => {
                commit("SET_NOTIFICATION_COUNT", res);
            });
        },
        req_getUpdateNotification({ commit }, input) {
            axios.get("notification/seen-notifi", input).then(() => {
                commit("UPDATE_NOTIFICATION_SUCCESS");
            });
        },
    },

    mutations: {
        SET_NOTIFICATION_SYSTEM: (state, data) => {
            state.listNotifySystem = data;
        },
        SET_NOTIFICATION_EVENT: (state, data) => {
            state.listNotifyEvent = data;
        },
        SET_NOTIFICATION_COUNT: (state, data) => {
            state.countNotify = data;
        },
        UPDATE_NOTIFICATION_SUCCESS() {
            this.dispatch('notify/req_getNotificationCount');
        },
    }
};
