const axios = require("../config/axios.config.js").default;
export default {
    namespaced: true,
    state: () => ({
        timeLimit: {},
        fee: {},
        historyInsurance: {},
    }),

    getters: {
        TimeLimit: (state) => { return state.timeLimit },
        Fee: (state) => { return state.fee },
        HistoryInsurance: (state) => { return state.historyInsurance }
    },

    actions: {
        postBuyInsurance: async function({ commit, dispatch }, input) {
            axios.post("insurance/buy", input).then(function(response) {
                if (response) {
                    commit("BuyInsuranceSuccess")
                    dispatch("req_getTimeLimit");
                    commit("userinfo/setBalace", {
                        main: true,
                        balance: response.Mainbalance,
                    }, { root: true });
                }
            })
        },
        req_getTimeLimit: async function ({ commit }, input) {
            axios.get("insurance/time-limit", {
                params: input
            }).then(function (response) {
                commit("GetTimeLimitSuccess", response)
            })
        },
    },

    mutations: {

        BuyInsuranceSuccess: function() {},
        GetTimeLimitSuccess: function(state, data) {
            state.timeLimit = data.time_limit;
            state.fee = data.fee;
            state.historyInsurance = data.history;
        },
    }
};