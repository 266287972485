export default {
  namespaced: true,
  state: () => ({
    typeBalanceSelected: "BS",
    currentTab: "userBet",
    balance: {},
    isRefreshDemo: "",
    popToast: {
      chara: "sonic",
      currency: "bs",
      message: "",
    },
  }),
  getters: {
    CurrentTab: (state) => state.currentTab,
    TypeBalanceSelected: (state) => state.typeBalanceSelected,
    BalanceSocket: (state) => {
      if (state.balance) {
        return state.balance;
      }
      return {};
    },
    IsRefreshDemo: (state) => state.isRefreshDemo,
    PopToast: (state) => state.popToast,
  },
  actions: {},
  mutations: {
    SET_TYPE_BALANCE_SELECTED(state, data) {
      state.typeBalanceSelected = data;
    },

    SET_CURRENT_TAB(state, data) {
      state.currentTab = data;
    },

    SET_POP_TOAST(
      state,
      {
        chara = state.popToast.chara,
        currency = state.popToast.currency,
        message = state.popToast.message,
      }
    ) {
      state.popToast = { chara, currency, message };
    },

    SET_BALANCE(state, data) {
      state.balance = data;
    },
    REFRESH_BALANCE_DEMO(state, data) {
      state.isRefreshDemo = data;
    },
  },
};
