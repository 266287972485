<script>
import { mapGetters } from "vuex";

export default {
  name: "NotiPop",
  computed: {
    ...mapGetters({
      PopToast: "socket/PopToast",
    }),
  },
};
</script>

<template>
  <b-modal hide-footer hide-header centered id="notiPop" size="lg">
    <div @click="$bvModal.hide('notiPop')">
      <div class="pop">
        <img
          class="character"
          :src="require(`@/assets/images/${PopToast.chara}.png`)"
          alt=""
        />
        <img
          class="coin"
          :src="
            require(`@/assets/images/currency/${PopToast.currency.toLowerCase()}.png`)
          "
          alt=""
        />
      </div>

      <span class="tititi">
        <span class="gradient-text">{{ $t("happy.notiPop") }}</span>
        {{ PopToast.message }}
      </span>
    </div>
  </b-modal>
</template>

<style lang="scss">
#notiPop {
  .modal-content {
    background: url(~@/assets/images/fetti.png) no-repeat;
    text-align: center;
    .pop {
      position: relative;
      .character {
        width: 100%;
        max-width: 450px;
        max-height: 300px;
        object-fit: contain;
      }
      .coin {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 100px;
      }
    }
    .tititi {
      font-size: 24px;
      font-weight: 600;
      > span {
        font-weight: 700;
        color: gold;
        background-image: radial-gradient(
          81.82% 2396.23% at 18.18% 52.94%,
          #ebbf62 0%,
          #ba7f24 44.27%,
          #9f6d1d 50.52%,
          #fbb639 82.81%
        );
        filter: drop-shadow(0px -2px 0px goldenrod);
      }
      @media (min-width: 768px) {
        font-size: 50px;
      }
    }
    img {
      max-width: 100%;
    }
  }
}
</style>
