<template>
  <div class="withdraw">
    <h3 class="title">{{ $t("wallet.deposit") }}</h3>
    <p class="sub-title">
      {{
        routerActive !== "stake"
          ? $t("wallet.sub_title_deposit_game")
          : $t("wallet.sub_title_deposit_stake_game")
      }}
    </p>
    <hr />
    <b-row class="withdraw-content" v-if="netWorkChoice">
      <b-col xl="12">
        <p class="labelInput">
          {{ $t("wallet.amount") }}
          <template v-if="netWorkChoice.name === 'Balance main'">
            ({{ $t("wallet.min") }}: ${{
              Price && Price.length > 0 ? Price[0].MinDepositBS : 20
            }})</template
          >
          <br />
          <!-- <span
            class="text-danger"
            v-if="netWorkChoice.name === this.$t('wallet.balance') + ' staking'"
            >(Note: You can only deposit up to 50% of the amount staked through
            the game)</span
          > -->
        </p>
        <div class="form-group">
          <input
            type="number"
            :value="StakingAmount"
            class="form-control custom-input"
            v-if="netWorkChoice.name === this.$t('wallet.balance') + ' staking'"
            readonly
          />
          <input
            type="number"
            v-model="amountDeposit"
            class="form-control custom-input"
            v-else-if="
              netWorkChoice.name !== this.$t('exchange.profit') + ' staking'
            "
          />
          <input
            type="number"
            :value="amountDeposit"
            class="form-control custom-input"
            v-else
            readonly
          />
          <div
            class="box-network"
            @click="showDropdownNetWork = !showDropdownNetWork"
          >
            <p class="text-center">
              {{ netWorkChoice.name }} <br />
              <span>
                {{ TruncateToDecimals2(+netWorkChoice.amount, "", 2) }}</span
              >
            </p>
            <img
              src="~@/assets/images/icons/gold-arrow-down.svg"
              alt="down"
              class="ml-1"
            />
            <div
              class="box-network-dropdown"
              v-if="
                showDropdownNetWork &&
                ListBalanceCanDeposit &&
                ListBalanceCanDeposit.length > 0
              "
            >
              <p
                class="text-center"
                v-for="balance in ListBalanceCanDeposit"
                :key="balance.name"
                @click.stop="onChoiceNetWork(balance)"
              >
                {{ balance.name }} <br />
                <span> {{ TruncateToDecimals2(+balance.amount, "", 2) }}</span>
                <img
                  src="~@/assets/images/icons/selected-balance.png"
                  alt=""
                  class="ml-1"
                  width="10px"
                  v-if="balance.name === netWorkChoice.name"
                />
              </p>
            </div>
          </div>
        </div>
        <div class="predict-list" v-if="showPredict">
          <div
            class="predict-item"
            @click="amountDeposit = amountPredict.amount1"
          >
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount1, "", 2) }}
          </div>
          <div
            class="predict-item"
            @click="amountDeposit = amountPredict.amount2"
          >
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount2, "", 2) }}
          </div>
          <div
            class="predict-item"
            @click="amountDeposit = amountPredict.amount3"
          >
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount3, "", 2) }}
          </div>
          <div
            class="predict-item"
            @click="amountDeposit = amountPredict.amount4"
          >
            <img src="~@/assets/images/currency/usd.png" alt="" class="mr-1" />
            {{ TruncateToDecimals2(amountPredict.amount4, "", 2) }}
          </div>
        </div>
        <div
          v-if="netWorkChoice.name !== this.$t('wallet.balance') + ' staking'"
        >
          <p class="labelInput">{{ $t("wallet.min") }}</p>
          <div class="form-group">
            <b-form-input
              type="text"
              class="form-control custom-input"
              :value="amountMin"
              readonly
            ></b-form-input>
          </div>
        </div>
        <div class="box-withdraw-info">
          <div class="withdraw-info-item">
            <div class="withdraw-info">
              <p class="labelInput text-white">{{ $t("wallet.fee") }}:</p>
              <p class="labelInput text-white">
                {{
                  netWorkChoice.name === this.$t("wallet.balance") + " staking"
                    ? 0
                    : 0
                }}%
              </p>
            </div>

            <hr />

            <div
              class="withdraw-info"
              v-if="
                this.netWorkChoice.name !==
                this.$t('wallet.balance') + ' staking'
              "
            >
              <p class="labelInput text-white">
                {{ $t("wallet.amount_expected_receive") }}:
              </p>
              <p class="labelInput text-white">
                {{ TruncateToDecimals2(+amountDeposit, "", 2) }}
                {{ netWorkChoice.symbol }}
              </p>
            </div>
          </div>
          <div class="withdraw-info-item">
            <b-button
              v-if="
                netWorkChoice.name === this.$t('wallet.balance') + ' staking'
              "
              class="btnNoStyle btn-withdraw"
              @click="onDepositGame(netWorkChoice.name)"
              >{{ $t("wallet.deposit") }}
            </b-button>
            <b-button
              v-else
              class="btnNoStyle btn-withdraw"
              @click="onDepositGame(netWorkChoice.name)"
              :disabled="
                !amountDeposit ||
                +amountDeposit > +netWorkChoice.amount ||
                +amountDeposit < +amountMin
              "
              >{{ $t("wallet.deposit") }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DepositBalanceMainIntoGamePageView",
  data() {
    return {
      amount: 0,
      showPredict: false,
      amountPredict: {
        amount1: 0,
        amount2: 0,
        amount3: 0,
        amount4: 0,
      },
      netWorkChoice: null,
      showDropdownNetWork: false,
      amountMin: 1,
      amountDeposit: 1,
      routerActive: null,
    };
  },
  computed: {
    ...mapGetters({
      Price: "wallet/Price",
      UserBalance: "userinfo/UserBalance",
      StatisticStake: "stake/StatisticStake",
    }),
    ListBalanceCanDeposit() {
      const arr = [];
      if (this.routerActive !== "stake") {
        arr.push({
          symbol: "BS",
          // 'Balance main'
          name: this.$t("wallet.balanceMain"),
          amount: this.UserBalance.MainBalance ?? 0,
        });
      }
      arr.push({
        symbol: "SBS",
        name: this.$t("wallet.balance") + " staking",
        amount: this.UserBalance.StakingBalance ?? 0,
      });
      arr.push({
        symbol: "SBS",
        name: this.$t("exchange.profit") + " staking",
        amount: this.UserBalance.totalDepositSBSProfit ?? 0,
      });
      return arr;
    },
    StakingAmount() {
      if (this.netWorkChoice.amount < 20) {
        return this.netWorkChoice.amount;
      }
      return this.netWorkChoice.amount / 2;
    },
  },
  watch: {
    amountDeposit: {
      handler(newVal) {
        if (newVal && +newVal !== 0) {
          this.showPredict = true;
          this.amountPredict = {
            amount1: this.amountDeposit * 10,
            amount2: this.amountDeposit * 100,
            amount3: this.amountDeposit * 1000,
            amount4: this.amountDeposit * 10000,
          };
          if (
            this.netWorkChoice.name ===
              this.$t("exchange.profit") + " staking" ||
            this.netWorkChoice.name === this.$t("wallet.balance") + " staking"
          ) {
            this.showPredict = false;
          }
          // ---------------
          // const amountSplit = newVal.toString().split('.');
          // if (amountSplit[1] && amountSplit[1].length > 2) {
          //   this.amountDeposit =
          //     this.enterTheNumberAfterTheComma2Numbers(amountSplit);
          // }
        } else {
          this.showPredict = false;
        }
      },
      deep: true,
    },
    ListBalanceCanDeposit: {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.netWorkChoice = newVal[0];
        }
      },
    },
    netWorkChoice: {
      handler(newVal) {
        if (newVal) {
          if (newVal.name === this.$t("wallet.balanceMain")) {
            this.amountDeposit = 1;
            if (this.Price && this.Price.length > 0) {
              this.amountMin = this.Price[0].MinDepositBS;
            } else {
              this.amountMin = 10;
            }
          }
          if (newVal.name === this.$t("wallet.balance") + " staking") {
            this.amountDeposit =
              +this.StatisticStake.balanceStake *
              (this.Price[0].MinDepositSBS / 100);
            if (this.amountDeposit > 10) {
              this.amountMin =
                +this.StatisticStake.balanceStake *
                (this.Price[0].MinDepositSBS / 100);
            } else {
              this.amountMin = 10;
            }
          }
          if (newVal.name === this.$t("exchange.profit") + " staking") {
            this.amountMin = this.UserBalance.totalDepositSBSProfit;
            this.amountDeposit = this.UserBalance.totalDepositSBSProfit;
          }
        }
      },
    },
  },
  methods: {
    onChoiceNetWork(balance) {
      this.showDropdownNetWork = false;
      this.netWorkChoice = balance;
    },
    resetForm() {
      this.amountDeposit = 1;
    },
    onDepositGame(type) {
      if (type === this.$t("wallet.balanceMain")) {
        this.$store
          .dispatch("wallet/req_postDepositFromBalanceMainToGame", {
            amount: this.amountDeposit,
          })
          .then(() => {
            this.resetForm();
            this.$bvModal.hide("deposit");
            this.$bvModal.hide("deposit-fast");
          });
      } else if (type === this.$t("wallet.balance") + " staking") {
        this.$store
          .dispatch("wallet/req_postDepositFromBalanceStakingToGame", {
            amount: this.StakingAmount,
          })
          .then(() => {
            this.resetForm();
            this.$bvModal.hide("deposit");
            this.$bvModal.hide("deposit-fast");
          });
      } else {
        this.$store
          .dispatch("wallet/req_postDepositFromProfitStakingToGame")
          .then(() => {
            this.$bvModal.hide("deposit");
            this.$bvModal.hide("deposit-fast");
          });
      }
    },
  },
  mounted() {
    this.routerActive = this.$route.name;
  },
  created() {
    this.$store.dispatch("userinfo/req_getBalance");
    this.$store.dispatch("stake/req_getStatisticStake");
  },
};
</script>

<style lang="scss" scoped>
.withdraw {
  .title {
    background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-size: 23px;

    @media (max-width: 1400px) {
      font-size: 20px;
    }
  }

  .sub-title {
    color: #bebebe;
    font-size: 16px;

    @media (max-width: 1400px) {
      font-size: 14px;
    }
  }

  hr {
    border: 1px solid rgba(93, 184, 251, 0.42);
  }

  .withdraw-content {
    align-items: center;

    .labelInput {
      margin-bottom: 5px;
      color: #616161;
      font-weight: 600;

      @media (max-width: 1400px) {
        font-size: 14px;
      }

      span {
        background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    .form-group {
      display: flex;
      align-items: center;
      height: 50px;

      input {
        height: 100%;
      }

      .box-network {
        height: 100%;
        margin-left: 10px;
        border: 1px solid #797979;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 200px;
        cursor: pointer;
        position: relative;

        p {
          margin-bottom: 0px;
          line-height: 100%;
          width: fit-content;

          @media (max-width: 1400px) {
            font-size: 14px;
          }

          span {
            background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            font-weight: bold;
          }
        }

        .box-network-dropdown {
          position: absolute;
          bottom: -135px;
          left: 0px;
          width: 100%;
          border: 1px solid #797979;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background: #1b2c57;
          z-index: 100;

          p {
            padding: 5px 0px;
            border-top: 1px solid #797979;

            &:first-child {
              border-top: 0;
            }
          }
        }
      }
    }

    .predict-list {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      overflow-x: auto;
      padding-bottom: 10px;

      .predict-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-basis: 150px;
        flex-shrink: 0;
        border: 1px solid rgba(93, 184, 251, 0.42);
        border-radius: 5px;
        text-align: center;
        height: 30px;
        cursor: pointer;
        padding: 0px 5px;
        margin: 0px 5px;

        &:hover {
          background: rgba(93, 184, 251, 0.42);
        }
      }
    }

    .box-withdraw-info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 576px) {
        flex-direction: column;
      }

      .withdraw-info-item {
        width: 48%;
        margin-top: 20px;

        @media (max-width: 576px) {
          width: 100%;
        }

        .withdraw-info {
          display: flex;
          justify-content: space-between;
          align-items: center;

          p {
            margin-bottom: 0px;
          }
        }

        .btn-withdraw {
          width: 100%;
          height: 50px;
          background: linear-gradient(
            91.46deg,
            #0ec5ff 1.08%,
            #3964fb 83%
          ) !important;
          border-radius: 3px;
          position: relative;
          z-index: 1;

          &::before {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 50px;
            background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
            filter: blur(21px);
            z-index: -1;
          }
        }
      }
    }

    .note {
      margin-top: 30px;
      font-size: 14px;

      span {
        background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    .withdraw-qr {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .withdraw-qr-address {
        width: 100%;
        background: #1b2c57;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 7px;
        display: flex;
        margin-top: 15px;
        padding: 15px 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: #bebebe;
          margin-bottom: 0px;

          &.copy {
            color: #ffffff;
          }
        }

        .line {
          width: 1px;
          height: 20px;
          margin-left: 10px;
          background-color: #bebebe;
        }

        img {
          margin: 0px 10px;
        }
      }
    }
  }
}
</style>
