const axios = require("../config/axios.config.js").default;
export default {
  namespaced: true,
  state: () => ({
    error: "",
    success: "",
    notificationImage: {},
    maintainer: 0,
    accountType: 2,
    netWork: 'BETA',
    // netWork: 'MAIN',
  }),

  getters: {
    Error: (state) => {
      return state.error;
    },
    Success: (state) => {
      return state.success;
    },
    NotificationImage: (state) => {
      return state.notificationImage;
    },
    Maintainer: (state) => {
      return state.maintainer;
    },
    Account: (state) => {
      return state.accountType;
    },
    IsNetWork: (state) => {
      return state.netWork
    }
  },

  actions: {
    req_getNotificationImages: async function ({ commit }) {
      axios
        .get("notification/get-notification", {
          params: {
            type: 2,
          },
        })
        .then(function (data) {
          if (data) {
            commit("SET_NOTIFICATION_IMAGE", data);
          }
        });
    },
    req_getMaintainer: async function ({ commit }) {
      axios.get("setting-main").then((data) => {
        if (data) {
          commit("SET_MAINTAINER_INFO", data);
        }
      });
    },
  },

  mutations: {
    SUCCESS_MESSANGE: function (state, messange) {
      state.success = messange;
    },
    ERROR_MESSANGE: function (state, messange) {
      state.error = messange;
    },
    SET_NOTIFICATION_IMAGE: function (state, data) {
      state.notificationImage = data;
    },
    SET_MAINTAINER_INFO: function (state, data) {
      state.maintainer = data.maintenance_excahnge.maintenance_ex;
    },
    SET_ACCOUNT_TYPE: function (state, data) {
      state.accountType = data;
    },
    HiddenChat: function () { },
    ShowChat: function () { },
    RemoveUser: function () { },
  },
};
