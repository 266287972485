<template>
  <div class="userInformation">
    <div class="userInformation-header">
      <p>{{ $t('profileModal.userinfo') }}</p>
      <b-button class="btnNoStyle" @click="$bvModal.hide('user-information')">
        <img src="@/assets/images/icons/close-white.svg" alt="" />
      </b-button>
    </div>
    <div class="userInformation-content">
      <img
        src="~@/assets/images/mockup/avatar-large.png"
        alt=""
        class="avatar"
      />
      <!-- {{ UserInfo.username }} -->
      <h3>{{ UserInfo.email }}</h3>
      <!-- {{ UserInfo.userId }} -->
      <p>{{ $t('profileModal.userid') }}: {{ UserInfo.userId }}</p>
      <p>{{ $t('profileModal.username') }}: {{ UserInfo.username ? UserInfo.username : 'N/A' }}</p>
      <!-- <img
        v-for="i in 5"
        :key="i"
        src="~@/assets/images/icons/icon-start.png"
        alt=""
      /> -->
    </div>
    <div class="userInformation-statistic">
      <div class="userInformation-statistic-item">
        <p>
          <img src="~@/assets/images/icons/icon-total-win.png" alt="" />
          {{ $t('profileModal.totalSuccesses') }}
        </p>
        <!-- {{ TruncateToDecimals2(BetData.quantityPlayWin ?? 0, '', 2) }} -->
        <p>{{ TruncateToDecimals2(UserBalance.totalWin || 0, '', 2) }}</p>
      </div>
      <div class="userInformation-statistic-item">
        <p>
          <img src="~@/assets/images/icons/icon-total-bet.png" alt="" />
          {{ $t('profileModal.totalWow') }}
        </p>
        <!-- {{ TruncateToDecimals2(BetData.quantityPlay ?? 0, '', 2) }} -->
        <p>{{ TruncateToDecimals2(UserBalance.totalBet || 0, '', 2) }}</p>
      </div>
      <div class="userInformation-statistic-item">
        <p>
          <img src="~@/assets/images/icons/icon-total-wager.png" alt="" />
          {{ $t('profileModal.totalWagered') }}
        </p>
        <p>{{ TruncateToDecimals2(UserBalance.totalWagered || 0, '', 2) }}</p>
      </div>
    </div>
    <div v-if="false" class="userInformation-bottom">
      <div class="userInformation-bottom-left">
        <img src="~@/assets/images/logo-full.png" alt="" width="150px"/>
        <p>Happy</p>
      </div>
      <div class="userInformation-bottom-right">
        <p>Weekly Wagered</p>
        <p>
          {{ TruncateToDecimals2(UserBalance.totalweeklyWagered || 0, '', 2) }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ModalInformationPageView',
  computed: {
    ...mapGetters({
      UserInfo: 'userinfo/UserInfo',
      UserBalance: 'userinfo/UserBalance',
    }),
  },
};
</script>

<style lang="scss" scoped>
.userInformation {
  // padding: 20px;
  .userInformation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(
      --header-1,
      linear-gradient(135deg, #00041b 0%, #000937 100%)
    );
    padding: 16px 23px;
    p {
      color: #ffffff;
      margin-bottom: 0px;
      font-weight: 500;
      font-size: 18px;
    }
    button {
      padding: 0px;
      color: #6a6a6a;
    }
  }
  .userInformation-content {
    text-align: center;
    margin-top: 30px;
    padding: 0 20px;
    .avatar {
      width: 100px;
    }
    h3 {
      margin: 10px auto;
      font-size: 20px;
      font-weight: 500;
    }
    p {
      margin: 0px 0px 5px 0px;
      color: #d4dff4;
      font-size: 14px;
    }
  }
  .userInformation-statistic {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 20px;
    .userInformation-statistic-item {
      width: calc(50% - 7px);
      min-width: 125px;
      background: #081b3b;
      border-radius: 2px;
      height: 100px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      p {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        color: rgb(255, 255, 255, 0.75);
        font-weight: 500;
        font-size: 14px;
        img {
          margin-right: 5px;
        }
        &:last-child {
          font-size: 18px;
          color: #d4dff4;
          margin-bottom: 0px;
        }
      }
      button {
        background: transparent;
        outline: none !important;
        border: 0;
        border-radius: 5px;
        box-shadow: unset;
        width: 100%;
        height: 100%;
        transition: 1s;
        span {
          font-size: 22px;
          background: -webkit-linear-gradient(#ffe500, #ff7b01);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          transition: 1s;
          @media (max-width: 576px) {
            font-size: 16px;
          }
        }
        &:hover {
          background: rgb(255, 255, 255, 0.25);
          span {
            background: -webkit-linear-gradient(#ff7b01, #ffe500);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
  .userInformation-bottom {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 40px;
    .userInformation-bottom-left {
      display: flex;
      align-items: center;
      img{
        @media (max-width: 576px){
          width: 100px;
        }
      }
      p {
        margin-bottom: 0px;
        color: #d4dff4;
        font-weight: 500;
        font-size: 22px;
        margin-left: 30px;
        @media (max-width: 576px) {
          font-size: 16px;
        }
      }
    }
    .userInformation-bottom-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      p {
        font-weight: 500;
        margin-bottom: 0px;
        font-size: 18px;
        @media (max-width: 576px) {
          font-size: 14px;
        }
        &:first-child {
          color: #8c989b;
        }
        &:last-child {
          color: #d4dff4;
        }
      }
    }
  }
}
</style>
