<template>
  <div class="notification-popup">
    <div class="notification-popup-header">
      <p>{{ $t('happy.notification') }}</p>
      <b-button
        @click="$store.commit('toggleNotify', false)"
        class="btnNoStyle"
      >
        <img src="@/assets/images/icons/close-white.svg" alt="" />
      </b-button>
    </div>
    <div class="notification-popup-list">
      <div class="notification-tab">
        <div class="listTab">
          <b-button
            class="btnNoStyle tab-item"
            :class="activeTab === 'system' ? 'activeTab' : ''"
            @click="activeTab = 'system'"
            >{{ $t('happy.system') }}</b-button
          >
          <b-button
            class="btnNoStyle tab-item"
            :class="activeTab === 'event' ? 'activeTab' : ''"
            @click="activeTab = 'event'"
            >{{ $t('happy.event') }}</b-button
          >
        </div>
      </div>
      <template v-if="activeTab === 'system'">
        <div
          class="notification-popup-item"
          v-for="noti in ListNotifySystem"
          :key="'noti: ' + noti.id"
        >
          <div class="notification-popup-item-header">
            <img src="~@/assets/images/icons/icon-speaker.png" alt="" />
            <p>{{ noti.log }}</p>
            <img src="~@/assets/images/icons/icon-speaker.png" alt="" />
          </div>
          <div class="notification-popup-item-time">
            <div class="notification-popup-item-time-left">
              <img
                :src="require('@/assets/images/logo-small.png')"
                alt="logo-small"
                class="mr-2"
              />
              <p>Bit Sonic Official</p>
            </div>
            <div class="notification-popup-item-time-right">
              <p>{{ noti.time }}</p>
            </div>
          </div>
          <hr />
          <div class="img-notification" v-if="false">
            <img
              src="~@/assets/images/mockup/notification-content.png"
              alt=""
            />
          </div>
          <p class="notification-content">- {{ $t('happy.log') }}: {{ noti.log }}</p>
          <p class="notification-content">- Device: {{ noti.device }}</p>
          <div class="notification-popup-item-groupBtn" v-if="false">
            <div class="groupBtn">
              <b-button class="btnNoStyle">
                Click here
                <img src="~@/assets/images/icons/icon-click.png" alt="" />
              </b-button>
              <b-button class="btnNoStyle">
                Click here to view our weekly update!
              </b-button>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeTab === 'event'">
        <div
          class="notification-popup-item"
          v-for="noti in ListNotifyEvent"
          :key="'noti: ' + noti.id"
        >
          <div class="notification-popup-item-header">
            <img src="~@/assets/images/icons/icon-speaker.png" alt="" />
            <p>{{ noti.title }}</p>
            <img src="~@/assets/images/icons/icon-speaker.png" alt="" />
          </div>
          <div class="notification-popup-item-time">
            <div class="notification-popup-item-time-left">
              <img
                :src="require('@/assets/images/logo-small.png')"
                alt="logo-small"
                class="mr-2"
              />
              <p>Bit Sonic Official</p>
            </div>
            <div class="notification-popup-item-time-right">
              <p>{{ noti.create_date }}</p>
            </div>
          </div>
          <hr />
          <div class="img-notification">
            <img :src="noti.image" alt="" />
          </div>
          <p class="notification-content d-flex" v-if="noti.conditions">
            - Conditions: <span class="ml-1" v-html="noti.conditions"></span>
          </p>
          <p class="notification-content d-flex" v-if="noti.description">
            - Description: <span class="ml-1" v-html="noti.description"></span>
          </p>
          <div class="notification-popup-item-groupBtn" v-if="false">
            <div class="groupBtn">
              <b-button class="btnNoStyle">
                Click here
                <img src="~@/assets/images/icons/icon-click.png" alt="" />
              </b-button>
              <b-button class="btnNoStyle">
                Click here to view our weekly update!
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="backdrop" @click="$store.commit('toggleNotify', false)"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'NotificationViewPage',
  data() {
    return {
      activeTab: 'system',
    };
  },
  computed: {
    ...mapGetters({
      ListNotifySystem: 'notify/ListNotifySystem',
      ListNotifyEvent: 'notify/ListNotifyEvent',
    }),
  },
};
</script>

<style lang="scss">
.notification-popup {
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100vh;
  background: linear-gradient(90.13deg, #00041b 0%, #000937 100%);
  padding: 10px 0px;
  z-index: 99999999;

  .notification-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    margin-bottom: 10px;

    p {
      margin-bottom: 0px;
      color: #ffffff;
      font-weight: 500;
    }

    button {
      color: #8c989b;
      font-weight: 500;
    }
  }

  .notification-popup-list {
    background: #01060f;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    .notification-tab {
      padding: 5px;
      background: rgba(27, 72, 187, 0.2);
      box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(34px);
      border-radius: 7px;
      width: fit-content;
      margin: 0 auto 15px;
      overflow-x: auto;
      overflow-y: hidden;

      @media (max-width: 576px) {
        width: 100%;
      }

      .listTab {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .tab-item {
          padding: 0px 25px;
          height: 40px;

          @media (max-width: 576px) {
            min-width: 130px;
          }

          &.activeTab {
            background: linear-gradient(91.46deg, #0ec5ff 1.08%, #3964fb 83%);
            border-radius: 6px;
          }
        }
      }
    }

    .notification-popup-item {
      background: #081b3b;
      border-radius: 5px;
      margin-bottom: 20px;
      padding-bottom: 15px;

      .notification-popup-item-header {
        padding: 15px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        p {
          color: #ffffff;
          font-weight: 500;
          margin: 0px 15px 0px 15px;
        }
      }

      .notification-popup-item-time {
        padding: 0px 10px 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .notification-popup-item-time-left {
          display: flex;
          align-items: center;

          img {
            width: auto;
            height: 35px;
          }

          p {
            margin-bottom: 0px;
            color: #8c989b;
            font-size: 12px;
          }
        }

        .notification-popup-item-time-right {
          p {
            margin-bottom: 0px;
            color: #d4dff4;
            font-size: 12px;
          }
        }
      }

      hr {
        background: #656565;
        margin-top: 0px;
      }

      .img-notification {
        padding: 0px 10px;
        text-align: center;

        img {
          width: auto;
          height: 150px;
        }
      }
      p {
        margin-bottom: 0px !important; 
      }

      .notification-content {
        margin-top: 10px;
        color: #8c989b;
        font-size: 14px;
        line-height: 130%;
        padding: 0px 10px;
      }

      .notification-popup-item-groupBtn {
        padding: 0px 10px;

        .groupBtn {
          background: #1b2c57;
          box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 4px;
          padding: 10px 10px;

          button {
            text-align: left;
            font-size: 14px;
            padding: 0px;

            img {
              margin-left: 5px;
            }

            &:nth-child(2) {
              background: linear-gradient(180deg, #ffe500 0%, #ff7b01 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              text-fill-color: transparent;
            }
          }
        }
      }
    }
  }

  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  right: 320px;
  bottom: 0;
  left: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.26);
  z-index: -1;
}
</style>